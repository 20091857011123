<template>

  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn :class="buttonclass" text v-on="on">{{ buttontext }}</v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>{{ caption }}</v-card-title>
      <v-card-text> Необходимо заполнить форму:</v-card-text>
      <v-card-actions>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >
          <v-text-field v-for="(key, index) in hints" :key="index"
                        v-model="values[index]"
                        :hint="key"
                        :placeholder="key"
                        required
                        :rules="reqRules"

          >
          </v-text-field>
        </v-form>

      </v-card-actions>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text

            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
            :disabled="!valid"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
module.exports =
    {
      name: 'TextInputDialog2',

      data: function () {
        return {
          dialog: false,
          values: [],
          valid:true,

          reqRules: [
            v => !!v || 'обязательное поле',
           // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
          ],

        }
      },

      props: ['tag', 'caption', 'hints', 'buttontext', 'buttonclass'],

      methods: {
        ok: function () {
          this.dialog = false;
          this.$emit('ok', this.tag, this.values)
        }
      },
    }
</script>
