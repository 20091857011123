var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status === 1
    ? _c("v-chip", { attrs: { color: "gray" } }, [_vm._v(" Новый")])
    : _vm.status === 2
    ? _c("v-chip", { attrs: { color: "yellow" } }, [_vm._v(" На модерации")])
    : _vm.status === 3
    ? _c("v-chip", { attrs: { color: "green" } }, [_vm._v(" Верифицированный")])
    : _vm.status === 4
    ? _c("v-chip", { attrs: { color: "orange" } }, [
        _vm._v(" Ошибка верификации"),
      ])
    : _vm.status === 5
    ? _c("v-chip", { attrs: { color: "red" } }, [_vm._v(" Заблокирован")])
    : _c("v-chip", { attrs: { color: "brown" } }, [
        _vm._v(" " + _vm._s(_vm.status)),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }