<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Пользователи обменника - Верификация </h3>
    </div>

    <div>
      <v-data-table
          :items="users_exchange"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.status_id="{ item }">
          <ExchangeUserStatusCell :status="item.status_id"></ExchangeUserStatusCell>
        </template>

        <template v-slot:item.main_passport_image="{ item }"><a v-if="item.main_passport_image" :href="item.main_passport_image">скачать</a>  </template>
        <template v-slot:item.registration_passport_image="{ item }"><a v-if="item.registration_passport_image" :href="item.registration_passport_image">скачать</a></template>
        <template v-slot:item.selfie_image="{ item }"><a v-if="item.selfie_image" :href="item.selfie_image">скачать</a></template>

        <template v-slot:item.actions="{ item }">
          <TextInputDialog buttonclass="red" buttontext="Отклонить" caption="Причина" :tag="item"
                           v-on:ok="revoke"></TextInputDialog>

          <TextInputDialog2 buttonclass="green" buttontext="Одобрить" caption="Одобрение" :tag="item"
                            v-on:ok="approve"
                            :hints="['Номер пасспорта','Прописка']"

          ></TextInputDialog2>

        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import {getHeaders} from "../store";
import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import ExchangeUserStatusCell from "../components/controls/ExchangeUserStatusCell.vue";
import TextInputDialog from "../components/dialogs/TextInputDialog.vue";
import TextInputDialog2 from "../components/dialogs/TextInputDialog2.vue";

//const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/user"

export default {
  name: 'ExchangeUsersVerify',

  components: {ExchangeUserStatusCell, Snackbar, TextInputDialog, TextInputDialog2},

  apollo: {
    $subscribe: {
      users: {
        query: gql`
subscription MyQuery {
  users_exchange(where: {status_id: {_eq: "2"}}) {
    verification_error_text
    user_verified_at
    updated_at
    status_id
    referer_code
    referral_code
    phone_number
    password

    passport_number
    registration_passport_image
    main_passport_image
    passport_address
    nickname
    id
    full_name
    created_at
    email

selfie_image

  }
}

        `,
        result({data}) {
          this.users_exchange = data.users_exchange;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    users_exchange: [],

    headers: [
      {value: "id", text: "ID"},
      {value: "nickname", text: "Никнейм"},
      {value: "email", text: "Email"},
      {value: "full_name", text: "ФИО"},
      {value: "phone_number", text: "Телефон"},


      //{ value: "status_id", text: "Статус" },

      //{ value: "USDT_balance", text: "Баланс USDT" },
      // { value: "BTC_balance", text: "Баланс BTC" },

      // { value: "passport_number", text: "Номер паспорта" },

      //{ value: "created_at", text: "Дата создания" },
      //{ value: "user_was_verified", text: "Дата верифицикации " },

      {value: "main_passport_image", text: "Основное изображение паспорта"},
      {value: "registration_passport_image", text: "Изображение регистрации паспорта"},
      {value: "selfie_image", text: "Селфи с паспортом"},

      // { value: "passport_address", text: "Адрес в паспорте" },

      //{ value: "updated_at", text: "Дата обновления" },
      //{ value: "verification_error", text: "Ошибка верификации" },
      //{ value: "referer_code", text: "Код реферерала" },
      //{ value: "referal_code", text: "Код реферера" },
      //{ value: "password", text: "Пароль" },

      {text: 'Действия', value: 'actions'},

    ],
  }),

  methods: {

    async revoke(user, text) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({verification_error_text: text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/users/${user.id}/verify_failed/1`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async approve(user, values) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          passport_number: values[0],
          passport_address: values[1],
        })
      };

      //put users/{user_id}/verificated/{token}
      let url = `${process.env.VUE_APP_EXCHANGE_API}/users/${user.id}/verificated/1`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },


    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

    compare2(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      let kne = []

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          kne.push(key)
        }
      }

      return kne;

    }

  },

}
</script>
