var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.users_exchange,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.status_id",
                fn: function ({ item }) {
                  return [
                    _c("ExchangeUserStatusCell", {
                      attrs: { status: item.status_id },
                    }),
                  ]
                },
              },
              {
                key: "item.main_passport_image",
                fn: function ({ item }) {
                  return [
                    item.main_passport_image
                      ? _c("a", { attrs: { href: item.main_passport_image } }, [
                          _vm._v("скачать"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.registration_passport_image",
                fn: function ({ item }) {
                  return [
                    item.registration_passport_image
                      ? _c(
                          "a",
                          { attrs: { href: item.registration_passport_image } },
                          [_vm._v("скачать")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.selfie_image",
                fn: function ({ item }) {
                  return [
                    item.selfie_image
                      ? _c("a", { attrs: { href: item.selfie_image } }, [
                          _vm._v("скачать"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c("TextInputDialog", {
                      attrs: {
                        buttonclass: "red",
                        buttontext: "Отклонить",
                        caption: "Причина",
                        tag: item,
                      },
                      on: { ok: _vm.revoke },
                    }),
                    _c("TextInputDialog2", {
                      attrs: {
                        buttonclass: "green",
                        buttontext: "Одобрить",
                        caption: "Одобрение",
                        tag: item,
                        hints: ["Номер пасспорта", "Прописка"],
                      },
                      on: { ok: _vm.approve },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Пользователи обменника - Верификация ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }