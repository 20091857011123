var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ class: _vm.buttonclass, attrs: { text: "" } }, on),
                [_vm._v(_vm._s(_vm.buttontext))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(_vm._s(_vm.caption)),
          ]),
          _c("v-card-text", [_vm._v(" Необходимо заполнить форму:")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                _vm._l(_vm.hints, function (key, index) {
                  return _c("v-text-field", {
                    key: index,
                    attrs: {
                      hint: key,
                      placeholder: key,
                      required: "",
                      rules: _vm.reqRules,
                    },
                    model: {
                      value: _vm.values[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.values, index, $$v)
                      },
                      expression: "values[index]",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", disabled: !_vm.valid },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }