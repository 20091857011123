
  <template v-slot:status="{ item }">
    <v-chip v-if="status===1" color="gray"> Новый</v-chip>
    <v-chip v-else-if="status===2" color="yellow"> На модерации</v-chip>
    <v-chip v-else-if="status===3" color="green"> Верифицированный</v-chip>
    <v-chip v-else-if="status===4" color="orange"> Ошибка верификации</v-chip>
    <v-chip v-else-if="status===5" color="red"> Заблокирован</v-chip>

    <v-chip v-else color="brown"> {{ status }}</v-chip>
  </template>


<script>
export default {
  name: "ExchangeUserStatusCell",

  props: [ 'status'],
//"Pending", "Processing", "Success", "Canceled"
}
</script>

<style scoped>

</style>